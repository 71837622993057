import { Injectable } from '@angular/core';
import {
  AdvocateRecommendedTaskClick,
  FilterComparators,
  O8RootQueryFilter,
  O8Lead,
  Pod,
  PrioritizedLeadRecord,
  PrioritizedLeadRecordDetails,
  RecommendedTask,
  UserProfile,
} from 'common.interfaces';
import { Observable, first, map, switchMap } from 'rxjs';
import { TasksApi } from './tasks.api';
import { withoutTrailingSlash } from '@origin8-web/o8-utils/format';
import { O8ConfigService } from '@origin8-web/core-ui/config';
import { Protec8Env } from '../../environments';
import { BrowserExtApiService } from '@origin8-web/core-ui/browser-ext-api';
import { fp } from '@origin8-web/o8-utils/fp';
import { UserService } from '@origin8-web/core-ui/login/providers';
import { EXCLUDED_PODS_FROM_RECOMMENDED_TASKS_FILTERS } from '../../app.interface';

@Injectable({
  providedIn: 'root',
})
export class TasksServiceGateway {
  constructor(
    private tasksApi: TasksApi,
    private configService: O8ConfigService<Protec8Env>,
    private browserExtApiService: BrowserExtApiService,
    private userService: UserService,
  ) {}

  getPrioritizedLeads(email: string): Observable<PrioritizedLeadRecord[]> {
    if (!email) {
      throw new Error('No email provided to fetch prioritized leads');
    }

    return this.tasksApi.getPrioritizedLeads(email);
  }

  getRecommendedTasks(email: string): Observable<RecommendedTask[]> {
    if (!email) {
      throw new Error('No email provided to fetch recommended tasks');
    }
    return this.tasksApi.getRecommendedTasks(email);
  }

  triggerAdvocateRecommendedTaskClickEvent(task: RecommendedTask) {
    return this.userService.getSelfOrImpersonatedUser$().pipe(
      first(),
      map((user) => {
        const event: AdvocateRecommendedTaskClick = {
          ...fp.pick(task, 'externalIdentifier', 'priority', 'task'),
          email: (user as UserProfile).email,
          positionDetail: task.leadInfo.positionDetail,
          timestamp: new Date(),
        };
        return event;
      }),
      switchMap((event) => {
        return this.tasksApi.triggerAdvocateRecommendedTaskClickEvent(event);
      }),
    );
  }

  getOpenLeadsByEmail(email: string): Observable<O8Lead[]> {
    if (!email) {
      throw new Error('No email provided to fetch open leads');
    }
    const filters: O8RootQueryFilter<O8Lead> = {
      and: [
        { key: 'email', comparator: FilterComparators.EQUAL, value: email },
        {
          key: 'leadStatus',
          comparator: FilterComparators.IN,
          value: [null, 'Helping', 'Application', 'New', 'Contacting', 'Payment', 'Missed Inbound Call'],
        },
      ],
    };
    return this.tasksApi.getOpenLeads(filters);
  }

  getPodFilterValues(): Observable<string[]> {
    const filters: O8RootQueryFilter<Pod> = {
      and: [
        { key: 'active', comparator: FilterComparators.EQUAL, value: true },
        {
          key: 'name',
          comparator: FilterComparators.NOT_IN,
          value: [null, ...EXCLUDED_PODS_FROM_RECOMMENDED_TASKS_FILTERS],
        },
      ],
    };
    return this.tasksApi.getPods(filters).pipe(map((pods) => pods.map((pod) => pod.name)));
  }

  getSalesforceLink = (lead: PrioritizedLeadRecordDetails): string => {
    const sfBaseUrl = withoutTrailingSlash(this.configService.get('salesForceBaseUrl'));
    return `${sfBaseUrl}/lightning/r/Lead/${lead.externalIdentifier}/view`;
  };

  openTaskInSf = (id: string): void => {
    const sfBaseUrl = withoutTrailingSlash(this.configService.get('salesForceBaseUrl'));
    this.browserExtApiService.triggerSfNavigation(id, sfBaseUrl);
  };
}
